<template>
  <RegisterForm />
</template>

<script>
// import { i18n } from "@/plugins/i18n";

export default {
  name: "Register",
  provide: {
    heading: { align: "center" },
  },
  components: {
    RegisterForm: () => import("@/components/auth/RegisterForm"),
  },
};
</script>
<style lang="sass" scoped>
::v-deep .no-active
  background-color: transparent !important
  opacity: 1 !important
  border: 0.17rem solid var(--v-primary-base)
  > span
    color: var(--v-primary-base)
</style>
